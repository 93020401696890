<template>
    <div class="flex flex-column flex-center">
        <img class="image" src="@/assets/common/placeholder.png" alt="placeholder">
        <p class="text">结果为空</p>
    </div>
</template>

<script>
export default {
    name: 'placeholder',
}
</script>

<style lang="scss" scoped>
@import "@/scss/reset.scss";
@import "@/scss/style.scss";

.image {
    width: 240 * $px;
    height: 164 * $px;
}

.text {
    font-size: 13 * $px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: rgba(52,56,75,0.75);
    margin-top: 20 * $px;
}

.flex {
    display: flex;
    flex-direction: row;
}

.flex-center {
    justify-content: center;
    align-items: center;
}

.flex-between {
    justify-content: space-between;
}

.flex-around {
    justify-content: space-around;
}

.flex-column {
    flex-direction: column;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-start {
    justify-content: flex-start;
}

.flex-end {
    justify-content: flex-end;
}

.overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.iphone-notch {
    padding-top: constant(safe-area-inset-top); /* 兼容 iOS < 11.2 */
    padding-top: env(safe-area-inset-top); /* 兼容 iOS >= 11.2 */
}

.home-indicator {
    padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
}

</style>